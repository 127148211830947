import './App.css';
import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material';
import { CssBaseline} from "@mui/material";
import { lightTheme, darkTheme } from "./theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./Controller/LayoutController/MainLayout";
import { routes } from "./Routes";
import Loading from './Controller/LoadingController/Loading';
import colorConfigs from './configs/colorConfigs';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './Error/ErrorBoundary';
import SignIn from './Authorization/SignIn';
import SignUp from './Authorization/SignUp';
import ForgotPassword from './Authorization/ForgotPassword';
import PrivateRoute from './PrivateRoute';

function PageNotFound() {
    return (
      <div>
        <h2>404 Page not found</h2>
      </div>
    );
}

function App() {
  const [light, setLight] = useState(false);
  return (
    <React.Fragment>
      <CssBaseline enableColorScheme={true}/>
         <ThemeProvider theme={light ? lightTheme : darkTheme}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Routes>
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/recoverpassword" element={<ForgotPassword />} />
                <Route path="/" element={
                  <React.Suspense fallback={<div style={{background: colorConfigs.mainBg, height: `100vh`}}>
                    <Loading loading={true} type={`dots`} backDrop={false}/>
                  </div>}>
                    <PrivateRoute>
                      <MainLayout />
                    </PrivateRoute>
                    </React.Suspense>           
                  }>
                  {routes}
                </Route>
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </ErrorBoundary>
        </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
