import * as React from "react";
import HomeIcon from "@mui/icons-material/Home";
import StreamIcon from "@mui/icons-material/Stream";
import DashboardLayout from "./DashboardLayout";

const HomeComponent = React.lazy(() => import("../Components/Maps/index"));

const appRoutes = [
  {
    index: true,
    element: <HomeComponent />,
    state: "Home",
  },

  {
    path: "/dashboard",
    state: "Dashboard",
    element: <DashboardLayout />,
    sidebarProps: {
      displayText: "Dashboard",
      icon: <HomeIcon />,
    },
    child: [
      {
        path: "/dashboard/live-overview",
        element: <HomeComponent />,
        state: "Home",
        sidebarProps: {
          displayText: "Live Overview",
          icon: <StreamIcon />,
        },
      },

      // {
      //   path: "/dashboard/dic-sensor",
      //   element: <DicComponent />,
      //   state: "Dic",
      //   sidebarProps: {
      //     displayText: "Dic Sensor",
      //     icon: <SensorsIcon />
      //   }
      // },

      // {
      //   path: "/dashboard/bin",
      //   element: <BinComponent />,
      //   state: "Litter Bins",
      //   sidebarProps: {
      //     displayText: "Litter Bins",
      //     icon: <DeleteSweepIcon />
      //   }
      // },
    ],
  },

  // {
  //   path: "/livereports",
  //   element: <LiveReport />,
  //   state: "LiveReport",
  //   sidebarProps: {
  //     displayText: "Live Reports",
  //     icon: <SummarizeIcon />,
  //   },
  // },

  // {
  //   path: "/trend",
  //   element: <TrendLayout />,
  //   state: "trend",
  //   sidebarProps: {
  //     displayText: "Trend",
  //     icon: <AssessmentIcon />,
  //   },
  //   child: [
  //     {
  //       path: "/trend/analysis",
  //       element: <TrendAnalysis />,
  //       state: "TrendAnalysis",
  //       sidebarProps: {
  //         displayText: "Trend Analysis",
  //         icon: <TrendingUpIcon />,
  //       },
  //     },

  //     {
  //       path: "/trend/overview",
  //       element: <TrendAnalysisOverview />,
  //       state: "TrendAnalysisOverview",
  //       sidebarProps: {
  //         displayText: "Trend Analysis Overview",
  //         icon: <BarChartIcon />,
  //       },
  //     },
  //   ],
  // },

  // {
  //   path: "/adduser",
  //   element: <AddUser />,
  //   state: "AddUser",
  //   sidebarProps: {
  //     displayText: "Add User",
  //     icon: <PersonAddIcon />,
  //   },
  // },

  // {
  //   path: "/streets",
  //   element: <StreetsLayout />,
  //   state: "street",
  //   sidebarProps: {
  //     displayText: "Streets",
  //     icon: <StreetviewIcon />,
  //   },
  //   child: [
  //     {
  //       path: "/streets/addstreets",
  //       element: <AddStreets />,
  //       state: "street.addstreets",
  //       sidebarProps: {
  //         displayText: "Add Streets",
  //         icon: <AddRoadIcon />,
  //       },
  //     },
  //     {
  //       path: "/streets/modifystreets",
  //       element: <ModifyStreets />,
  //       state: "street.modifystreets",
  //       sidebarProps: {
  //         displayText: "Streets",
  //         icon: <EditRoadIcon />,
  //       },
  //     },
  //   ],
  // },

  // {
  //   path: "/areas",
  //   element: <Areas />,
  //   state: "Area",
  //   sidebarProps: {
  //     displayText: "Area",
  //     icon: <SummarizeIcon />,
  //   },
  // },

  /*  {
     path: "/streets",
    element: <ModifyStreets />,
    //state: "Streets",
    sidebarProps: {
      displayText: "Streets",
      icon: <EditRoadIcon />
    },
    child: [
      {
        path: "/modifystreets",
        element: <ModifyStreets />,
        state: "ModifyStreets",
        sidebarProps: {
          displayText: "Modify Streets",
          icon: <EditRoadIcon />
        }
      }, 
      {
        path: "/addstreets",
        element: <AddStreets />,
        state: "AddStreets",
        sidebarProps: {
          displayText: "Add Streets",
          icon: <AddRoadIcon />
        }
      }, 
    ]
},*/
];

export default appRoutes;
